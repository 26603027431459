<template>
  <div class="row">
    <div
      :class="[
        showOutput ? 'col-sm-9 col-sm-9-mw-70' : 'col-sm-12',
        'rw-accordions px-1'
      ]"
    >
      <b-card class="" body-class="p-0">
        <div role="tablist">
          <b-card
            no-body
            v-for="(segment, index) in visibleSegments"
            :key="index"
            :class="[
              { 'active-card': activeSegment === segment },
              'mb-1 std-border'
            ]"
          >
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-btn
                block
                href="#"
                v-b-toggle="`accordion_${index}`"
                class="accord-btn"
              >
                <b-row>
                  <b-col cols="10">
                    <div class="d-flex align-items-center">
                      <span :class="['pr-2', icons[segment]]"></span>
                      <h6 class="mb-0">
                        <b>{{ segment }}</b>
                        <span
                          v-if="showPremiumContentMessage(segment)"
                          class="text-muted small px-1"
                          >- Premium ($) Content
                        </span>
                        <span
                          v-if="showFINReporterNote(segment)"
                          class="text-muted small px-1"
                        >
                          (Not available for Peer Group Average)
                        </span>
                        <span
                          v-else-if="showIndustryReporterNote(segment)"
                          class="text-muted small px-1"
                        >
                          (Not available for Industry Reporter)
                        </span>
                      </h6>
                    </div>
                  </b-col>
                  <b-col cols="2" class="text-right">
                    <i class="fa fa-lg fa-caret-down" aria-hidden="true"></i>
                  </b-col>
                </b-row>
              </b-btn>
            </b-card-header>
            <b-collapse
              role="tabpanel"
              :id="`accordion_${index}`"
              :visible="activeSegment === segment"
              @shown="selectSegment(segment)"
            >
              <b-card-body class="p-2">
                <keep-alive>
                  <component
                    v-bind:is="segmentComponent(segment)"
                    :ref="segment.replace(/\W/g, '')"
                    :selectedReport="selectedReport"
                    :showOutput="showOutput"
                    :renderedIn="renderedIn"
                    :isMortgageLender="isMortgageLender"
                    :class="{
                      'segment-disabled': showIndustryReporterNote(segment)
                    }"
                  ></component>
                </keep-alive>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
      </b-card>
    </div>
    <div class="col-sm-4 col-sm-4-mw-30 px-1" v-if="showOutput">
      <output-view-card
        :output-editable="!renderedOnIndustryReporter"
        :renderedIn="renderedIn"
      ></output-view-card>
    </div>
    <b-modal
      id="accessPremiumContent"
      centered
      @ok="show = false"
      title="Premium Content"
      ok-only
    >
      <p class="text-center">
        Additional fees will apply for data elements labeled as
        <br /><b>Premium ($) Content</b>.
      </p>
    </b-modal>
  </div>
</template>

<script>
//global
import { mapState, mapGetters, mapMutations } from 'vuex'
// ui components
import MortgageLending from '../components/MortgageLending'
import Firmographics from '../components/Firmographics'
import Segmentation from '../components/Segmentation'
import Technographics from '../components/Technographics'
import Performographics from '../components/Performographics'
import MarketArea from '../components/MarketArea'
import FinancialMetrics from '../components/FinancialMetrics'
import CommonMetrics from '../components/CommonMetrics'
import FinancialStatements from '../components/FinancialStatements'
import DigitalChannels from '../components/DigitalChannels'
import FIContacts from '../components/FIContacts'
import VendorFit from '../components/VendorFit'
import RegulatoryStatements from '../components/RegulatoryStatements'
import OutputViewCard from '../components/OutputViewCard'

export default {
  name: 'CustomReport',
  components: {
    MortgageLending,
    Firmographics,
    Segmentation,
    Technographics,
    Performographics,
    MarketArea,
    FinancialMetrics,
    CommonMetrics,
    FinancialStatements,
    DigitalChannels,
    VendorFit,
    FIContacts,
    RegulatoryStatements,
    OutputViewCard
  },
  data() {
    return {
      activeSegment: null
    }
  },
  props: {
    showOutput: {
      type: Boolean,
      default: true
    },
    selectedReport: {
      type: Object,
      required: true
    },
    renderedIn: {
      type: String,
      default: 'ReportWriter'
    }
  },
  mounted() {
    // this.selectSegment(this.segments[0])
  },
  computed: {
    ...mapState('ReportWriter', {
      segments: state => state.segments,
      icons: state => state.segmentIcons,
      irSegemnts: state => state.irSegemnts
    }),
    ...mapState('Ability', ['showPremiumContentAlert']),
    ...mapGetters('Ability', [
      'isDigitalChannelsAvailable',
      'isFIContactsAvailable',
      'isFinQueryAvailable',
      'showDigitalChannels'
    ]),
    ...mapGetters('ReportWriter', [
      'accessibleSegments',
      'accessibleSegmentsForMBC'
    ]),

    ...mapGetters('AdvancedQuery', ['isMortgageLender']),
    //...mapGetters('AdvancedQuery', []),
    fiType() {
      return this.selectedReport.fi_type
    },
    renderedOnReporterTool() {
      return this.renderedIn === 'FIN Reporter'
    },
    renderedOnIndustryReporter() {
      return this.renderedIn === 'Industry Reporter'
    },
    renderedOnFINQuery() {
      return this.renderedIn === 'FIN Query'
    },
    visibleSegments() {
      return this.renderedOnFINQuery
        ? this.isMortgageLender
          ? this.accessibleSegmentsForMBC
          : this.accessibleSegments
        : this.segmentsForReporter
    },
    segmentsForReporter() {
      return this.showDigitalChannels
        ? this.accessibleSegments.filter(
            seg => !['FI Contacts', 'VendorFit'].includes(seg)
          )
        : this.accessibleSegments.filter(
            seg =>
              !['Digital Channels', 'FI Contacts', 'VendorFit'].includes(seg)
          )
    }
  },
  methods: {
    ...mapMutations('ReportWriter', ['setActiveSegment']),
    ...mapMutations('Ability', ['setShowPremiumContentAlert']),
    segmentComponent(segment) {
      let component =
        this.fiType === 'both' && segment === 'Financial Metrics'
          ? 'Common Metrics'
          : segment
      return component.replace(/\W/g, '')
    },
    showFINReporterNote(segment) {
      return this.renderedOnReporterTool && !this.irSegemnts.includes(segment)
    },
    showIndustryReporterNote(segment) {
      return (
        this.renderedOnIndustryReporter && !this.irSegemnts.includes(segment)
      )
    },
    segmentEditable(segment) {
      return this.renderedIn === 'Industry Reporter'
        ? this.irSegemnts.includes(segment)
        : true
    },
    selectSegment(segment) {
      let activeComponent = this.$refs[segment.replace(/\W/g, '')][0]
      this.activeSegment = segment
      this.setActiveSegment(segment)
      if (activeComponent) {
        activeComponent.loadInitData()
      }
      if (
        this.showPremiumContentAlert &&
        this.showPremiumContentMessage(segment)
      ) {
        this.$bvModal.show('accessPremiumContent')
        this.setShowPremiumContentAlert(false)
      }
    },
    reloadActiveSegment() {
      if (this.activeSegment) {
        this.setActiveSegment(this.activeSegment)
        let activeComponent = this.$refs[
          this.activeSegment.replace(/\W/g, '')
        ][0]
        activeComponent.loadInitData()
      }
    },
    isPremiumContent(segment) {
      if (segment === 'Digital Channels' && this.showDigitalChannels)
        return false
      else if (segment === 'FI Contacts' && this.isFIContactsAvailable)
        return false
      else if (
        !['Digital Channels', 'FI Contacts'].includes(segment) &&
        this.isFinQueryAvailable
      )
        return false
      else return true
    },
    showPremiumContentMessage(segment) {
      return (
        this.isPremiumContent(segment) &&
        !this.renderedOnReporterTool &&
        !this.renderedOnIndustryReporter
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.rw-accordions {
  .accord-btn.btn-secondary {
    &:focus {
      box-shadow: none;
    }

    &:hover {
      box-shadow: none;
    }
  }

  /deep/ .option-disabled {
    pointer-events: none;
    opacity: 50%;
  }

  /deep/ .tree-menu {
    background-color: #f0f3f5;

    .list-group {
      .list-group-item {
        cursor: pointer;
        border: none;
        padding: 0.3rem;
        background-color: transparent;
        font-weight: 500;

        &.active {
          background-color: #20a8d8;
        }

        &:hover {
          background-color: #20a8d8;
        }
      }
    }
  }

  /deep/ .segment-column {
    height: 40rem;
    overflow-y: auto;
    overflow-x: hidden;

    .sub-title {
      font-size: 13px;
      font-weight: 500;
      color: #6c6d70;
    }
  }

  /deep/ .segment-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
</style>

<style lang="scss">
.rw-accordions {
  .tree-content {
    padding-top: 0px;
    padding-bottom: 0px;

    i.tree-arrow {
      width: 23px;
      height: 20px;
      margin-left: 0;

      &:after {
        height: 7px;
        width: 7px;
      }
    }
  }

  .tree-anchor {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .tree-checkbox {
    width: 15px !important;
    height: 15px !important;

    &.checked:after {
      left: 4px;
      top: 2px;
      height: 6px;
      width: 3px;
    }
  }

  .tree-node.disabled {
    .tree-content {
      .tree-anchor {
        color: #343434;
      }
    }
  }

  &.col-sm-9 {
    transition: 0.5s;
  }

  &.col-sm-12 {
    transition: 0.5s;
  }
}
</style>
